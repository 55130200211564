import "../lib/select2";
import "../lib/trix_attachment";
import "../lib/input_filter";

$(document).ready(function () {
  // book design Consultation
  setTimeout(function () {
    $(".box-notify").fadeIn("slow");
  }, 10000);
  setTimeout(function () {
    $(".box-notify").fadeOut("slow");
  }, 25000);

  // navbar animation
  var navBarToggler = $(".navbar-toggler");
  navBarToggler.on("click", function () {
    navBarToggler.toggleClass("active");
  });

  $(".content-select2").select2({
    tags: "true",
    placeholder: "Add #hashtags",
    tokenSeparators: [","],
  });

  if (typeof Plyr != "undefined") {
    var element = new Plyr("#custom_player");
  }

  var template;

  template = function (item) {
    return item.text;
  };

  $(".posts-tags-select2").select2({
    tags: true,
    placeholder: "Who can access? Eg: Level 1 or Silver Members",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    // maximumSelectionLength: 2,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        var accountID = $(this).data("account-id");
        console.log(accountID);
        return {
          term: params.term,
          account_id: accountID,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".knowledge-articles-tags-select2").select2({
    tags: true,
    placeholder: "Who can access? Eg: Level 1 or Silver Members",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        var accountID = $(this).data("account-id");
        console.log(accountID);
        return {
          term: params.term,
          account_id: accountID,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".events-tags-select2").select2({
    tags: true,
    placeholder: "Who can access? Eg: Level 1 or Silver Members",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        var accountID = $(this).data("account-id");
        console.log(accountID);
        return {
          term: params.term,
          account_id: accountID,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".courses-tags-select2").select2({
    tags: true,
    placeholder: "Who can access? Eg: Level 1 or Silver Members",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        var accountID = $(this).data("account-id");
        console.log(accountID);
        return {
          term: params.term,
          account_id: accountID,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".tutorials-tags-select2").select2({
    tags: true,
    placeholder: "Add categories",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        return {
          term: params.term,
          tutorial: 1,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".global-blog-tags-select2").select2({
    tags: true,
    placeholder: "Categorise this article",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        return {
          term: params.term,
          global_blog: 1,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".courses-target-group-tags-select2").select2({
    tags: true,
    placeholder:
      "eg: Entrepreneurs, Freelancers, Designers, Content Writers, Engineers",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        return {
          term: params.term,
          course_target_groups: 1,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".startups-tags-select2").select2({
    tags: true,
    placeholder: "eg: SAAS, AI, e-commerce, community etc",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        return {
          term: params.term,
          startup: 1,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".account-tags-select2").select2({
    tags: true,
    placeholder: "eg: Entrepreneurship, Marketing, Coding, Design",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    maximumSelectionLength: 3,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        return {
          term: params.term,
          account: 1,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".account-plan-tags-select2").select2({
    tags: true,
    placeholder: "Eg: Level 1, Silver",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    // maximumSelectionLength: 3,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        var additionalValue = $(this).data("additional-param");
        console.log(additionalValue);
        return {
          term: params.term,
          account_plan: 1,
          account_id: additionalValue,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".account-category-tags-select2").select2({
    tags: true,
    placeholder: "eg: Marketing, Seo",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    maximumSelectionLength: 3,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        return {
          term: params.term,
          account_category: 1,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  $(".custom-gpt-category-tags-select2").select2({
    tags: true,
    placeholder: "Categorize the gpt",
    inputTooShort: function () {
      return "";
    },
    minimumInputLength: 1,
    maximumSelectionLength: 3,
    tokenSeparators: [","],
    delay: 200,
    ajax: {
      url: "/tags",
      data: function (params) {
        return {
          term: params.term,
          custom_gpt_category: 1,
        };
      },
      dataType: "json",
      processResults: function (data, params) {
        return { results: data };
      },
      cache: true,
    },
    createSearchChoice: function (term, data) {
      if (
        $(data).filter(function () {
          return this.text.localeCompare(term) === 0;
        }).length === 0
      ) {
        return {
          id: term,
          text: term,
        };
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: template,
    templateSelection: template,
  });

  var myModalEl = document.getElementById("editUserProfileModal");
  if (myModalEl) {
    myModalEl.addEventListener("shown.bs.modal", function (event) {
      $(".user-expertise-tags-select2").select2({
        tags: true,
        dropdownParent: $("#editUserProfileModal"),
        placeholder:
          "eg: SEO, UX, Figma, React Native, Manufacturing, growth hacking",
        inputTooShort: function () {
          return "";
        },
        minimumInputLength: 1,
        maximumSelectionLength: 5,
        tokenSeparators: [","],
        delay: 200,
        ajax: {
          url: "/tags",
          data: function (params) {
            return {
              term: params.term,
              user_expertise: 1,
            };
          },
          dataType: "json",
          processResults: function (data, params) {
            return { results: data };
          },
          cache: true,
        },
        createSearchChoice: function (term, data) {
          if (
            $(data).filter(function () {
              return this.text.localeCompare(term) === 0;
            }).length === 0
          ) {
            return {
              id: term,
              text: term,
            };
          }
        },
        escapeMarkup: function (markup) {
          return markup;
        },
        templateResult: template,
        templateSelection: template,
      });

      $(".user-industry-tags-select2").select2({
        tags: true,
        dropdownParent: $("#editUserProfileModal"),
        placeholder: "eg: eCommerce, Real Estate, Education, Media",
        inputTooShort: function () {
          return "";
        },
        minimumInputLength: 1,
        maximumSelectionLength: 5,
        tokenSeparators: [","],
        delay: 200,
        ajax: {
          url: "/tags",
          data: function (params) {
            return {
              term: params.term,
              user_industry: 1,
            };
          },
          dataType: "json",
          processResults: function (data, params) {
            return { results: data };
          },
          cache: true,
        },
        createSearchChoice: function (term, data) {
          if (
            $(data).filter(function () {
              return this.text.localeCompare(term) === 0;
            }).length === 0
          ) {
            return {
              id: term,
              text: term,
            };
          }
        },
        escapeMarkup: function (markup) {
          return markup;
        },
        templateResult: template,
        templateSelection: template,
      });
    });
  }

  // password eye functionality
  var user_password = $("#user_password");
  var user_password_icon = $(".user_password_icon");
  var user_password_confirmation = $("#user_password_confirmation");
  var user_password_confirmation_icon = $(".user_password_confirmation_icon");
  user_password_icon.on("click", function () {
    if (user_password.attr("type") === "password") {
      user_password.attr("type", "text");
      user_password_icon.html("<i class='fas fa-eye'></i>");
    } else {
      user_password.attr("type", "password");
      user_password_icon.html("<i class='fas fa-eye-slash'></i>");
    }
  });
  user_password_confirmation_icon.on("click", function () {
    if (user_password_confirmation.attr("type") === "password") {
      user_password_confirmation.attr("type", "text");
      user_password_confirmation_icon.html("<i class='fas fa-eye'></i>");
    } else {
      user_password_confirmation.attr("type", "password");
      user_password_confirmation_icon.html("<i class='fas fa-eye-slash'></i>");
    }
  });

  // Challenge form
  $(".challenge-form #course_discounted_price").on("keyup", function () {
    // !!$(this).value  -> if value exists

    // console.log(!!$(this).val())
    if (!!$(this).val()) {
      $("#course_course_price").attr("required", "required");
    } else {
      $("#course_course_price").removeAttr("required");
    }
  });

  // new startup form wizard
  var newStartupFormWizard = {
    config: {
      element: $("#newStartupForm"),
      steps: $("#newStartupForm .tab"),
      formControls: $("#newStartupForm .form-control"),
      btnPrev: $("#newStartupForm .prevBtn"),
      btnNext: $("#newStartupForm .nextBtn"),
      currentStepIdx: 0,
    },
    init: function () {
      console.log("this ran");
      this.showStep(this.config.currentStepIdx);
      this.addEvents();
    },
    showStep: function (prop_stepIdx = 0) {
      const stepIdx = prop_stepIdx;
      this.config.steps.eq(stepIdx).removeClass("d-none");
      this.config.steps.eq(stepIdx).find(".form-control")[0].focus();
    },
    addEvents: function () {
      this.config.btnNext.on("click", function () {
        newStartupFormWizard.prevNext(1);
      });
      this.config.btnPrev.on("click", function () {
        newStartupFormWizard.prevNext(-1);
      });
    },
    prevNext: function (prop_value = 0) {
      event.preventDefault();
      const value = prop_value;
      if (value === 1 && !this.validate()) {
        return false;
      }
      /* dont hide if its the last step */
      if (this.config.currentStepIdx + value != this.config.steps.length) {
        this.config.steps.eq(this.config.currentStepIdx).addClass("d-none");
      }

      this.config.currentStepIdx += value;

      /* submit if its the last step */
      if (this.config.currentStepIdx == this.config.steps.length) {
        this.config.element.submit();
        return false;
      }

      this.showStep(this.config.currentStepIdx);
    },
    validate: function () {
      var valid = true;
      var inputs = this.config.steps
        .eq(this.config.currentStepIdx)
        .find(".form-control");
      for (var i = 0; i < inputs.length; i++) {
        if (inputs.eq(i).hasClass("required") && inputs[i].value == "") {
          inputs
            .eq(i)
            .parent("div")
            .find(".nsf-error")
            .text("This field is required*")
            .show();
          valid = false;
        } else if (
          inputs[i].type == "url" &&
          inputs.eq(i).val() != "" &&
          !inputs[i].checkValidity()
        ) {
          inputs
            .eq(i)
            .parent("div")
            .find(".nsf-error")
            .text("Invalid Url*")
            .show();
          valid = false;
        } else {
          inputs.eq(i).parent("div").find(".nsf-error").hide();
        }
      }
      return valid;
    },
  };
  if ($("#newStartupForm").length) {
    newStartupFormWizard.init();
  }

  // dismiss flash alerts
  $(".flash-alert").delay(10000).fadeOut(800);

  // floating_button
  $(".floating-btn .toggle").on("click", function () {
    $(this).toggleClass("active");
    $(this).parent(".floating-btn").toggleClass("active");
  });

  $(".floating-btn .toggle li a").on("click", function () {
    console.log("link clicked");
    $(this).parent(".toggle").toggleClass("active");
    $(this).parent(".floating-btn").toggleClass("active");
  });

  // Toggle header search
  $(".header-search-icon").on("click", function () {
    $(".search_bar").slideToggle("fast");
  });

  // Startup private toggle
  $(".startup-edit .form-toggle #startup_private").on("change", function () {
    $(".form-toggle").submit();
  });

  // search_bar search
  var typingTimer; //timer identifier
  var doneTypingInterval = 500; //time in ms (5 seconds)

  $(".search_bar input").keyup(function () {
    $(".search_bar .loader").removeClass("d-none");
    clearTimeout(typingTimer);
    typingTimer = setTimeout(doneTyping, doneTypingInterval);
  });
  function doneTyping() {
    //user is "finished typing," do something
    $.get(
      $(".search_bar").attr("action"),
      $("form.search_bar").serialize(),
      null,
      "script"
    );
    $(".search_bar .loader").addClass("d-none");
  }

  // header_search_bar search

  $(".header-search input").keyup(function () {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(hs_doneTyping, doneTypingInterval);
  });
  function hs_doneTyping() {
    //user is "finished typing," do something
    $.get(
      $(".header-search").attr("action"),
      $("form.header-search").serialize(),
      null,
      "script"
    );
  }

  // accounts sidebar search_bar search
  var accountsSidebarTypingTimer; //timer identifier
  var accountsSidebarDoneTypingInterval = 500; //time in ms (5 seconds)

  $(".accounts_sidebar_search_bar input").keyup(function () {
    $(".accounts_sidebar_search_bar .loader").removeClass("d-none");
    clearTimeout(accountsSidebarTypingTimer);
    accountsSidebarTypingTimer = setTimeout(
      accounts_sidebar_doneTyping,
      accountsSidebarDoneTypingInterval
    );
  });
  function accounts_sidebar_doneTyping() {
    //user is "finished typing," do something
    $.get(
      $(".accounts_sidebar_search_bar").attr("action"),
      $("form.accounts_sidebar_search_bar").serialize(),
      null,
      "script"
    );
    $(".accounts_sidebar_search_bar .loader").addClass("d-none");
  }

  // infinite-scroll
  if ($(".infinite-scroll .pagination").length) {
    $(window).scroll(function () {
      var url = $(".infinite-scroll .pagination .next_page").attr("href");
      if (
        url &&
        $(window).scrollTop() > $(document).height() - $(window).height() - 1500
      ) {
        $(".pagination").text("Please Wait...");
        return $.getScript(url);
      }
    });
  }

  // Home page animation /home
  if ($("#text-rotation").length) {
    var txt_array = [
      "a Professional Network",
      "an entrepreneur community",
      "a cohort-based course",
      "an Angel Syndicate",
    ];
    var txt_array_count = 0;
    setInterval(function () {
      txt_array_count++;
      $("#text-rotation").fadeOut(800, function () {
        $(this)
          .text(txt_array[txt_array_count % txt_array.length])
          .fadeIn(800);
      });
    }, 2500);
  }

  // Disable button after one click
  $("form.disable-on-submit").on("submit", function () {
    $(this).find("button[type='submit']").attr("disabled", true).append("...");
  });

  // Disable button after one click
  $("form.ai-disable-on-submit").on("submit", function () {
    // console.log("disabled on")
    $(this)
      .find("button[type='submit']")
      .attr("disabled", true)
      .css("background", "#fbe137");
    $(this)
      .find("button[type='submit'] i")
      .removeClass("text-ea")
      .addClass("text-dark");
  });

  // Disable button after one click
  $("form.ai-conversation-form").on("submit", function () {
    // console.log("disabled on")
    $(this)
      .find("button[type='submit']")
      .attr("disabled", true)
      .text("Generating...");
  });

  // Disable button after one click
  $(".ai-summary-disable-on-submit").on("click", function (event) {
    console.log("disabled on");
    event.preventDefault();
    const link = $(this);
    link.addClass("disabled");
    link.css("pointer-events", "none");
    link.css("opacity", "0.6");
    link.text("Generating site with AI");
    link.append("...");

    // Redirect after disabling the link
    setTimeout(() => {
      window.location.href = link.attr("href");
    }, 100);
  });

  // Hide/Show my usecases in home
  $("#use-cases span.view-more").on("click", function () {
    $(".use-case-list div:hidden").show();
    if (
      $(".use-case-list div").length == $(".use-case-list div:visible").length
    ) {
      $("#use-cases span.view-more").hide();
    }
  });
  if (
    $(".use-case-list div").length != $(".use-case-list div:visible").length
  ) {
    $("#use-cases span.view-more").show();
  } else {
    $("#use-cases span.view-more").hide();
  }

  // Hide/Show my startups in left sidebar
  $(".more-startups span").on("click", function () {
    $(".startups-list li:hidden").show();
    if (
      $(".startups-list li").length == $(".startups-list li:visible").length
    ) {
      $(".more-startups span ").hide();
    }
  });
  if ($(".startups-list li").length != $(".startups-list li:visible").length) {
    $("span.my-startups-datalist-more ").show();
  }

  $(".more-networks span").on("click", function () {
    $(".user-networks-list .network:hidden").show();
    if (
      $(".user-networks-list .network").length ==
      $(".user-networks-list .network:visible").length
    ) {
      $(".more-networks ").hide();
    }
  });
  if (
    $(".user-networks-list .network").length !=
    $(".user-networks-list .network:visible").length
  ) {
    $(".more-networks ").show();
  } else {
    $(".more-networks ").hide();
  }

  $(".more-article-tags").on("click", function () {
    $(".article-tags--list .article-tag:hidden").show();
    if (
      $(".article-tags--list .article-tag").length ==
      $(".article-tags--list .article-tag:visible").length
    ) {
      $(".more-article-tags").hide();
    }
  });
  if (
    $(".article-tags--list .article-tag").length !=
    $(".article-tags--list .article-tag:visible").length
  ) {
    $(".more-article-tags").show();
  } else {
    $(".more-article-tags").hide();
  }

  $(".more-article-tags-sb").on("click", function () {
    $(".article-tags--list-sb .article-tag-sb:hidden").show();
    if (
      $(".article-tags--list-sb .article-tag-sb").length ==
      $(".article-tags--list-sb .article-tag-sb:visible").length
    ) {
      $(".more-article-tags-sb").hide();
    }
  });
  if (
    $(".article-tags--list-sb .article-tag-sb").length !=
    $(".article-tags--list-sb .article-tag-sb:visible").length
  ) {
    $(".more-article-tags-sb").show();
  } else {
    $(".more-article-tags-sb").hide();
  }

  // Preview Post Images
  var images = function (input, imgPreview) {
    if (input.files) {
      var filesAmount = input.files.length;
      for (var i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = function (event) {
          $(
            $.parseHTML(
              "<img class='pic float-left mb-2' style='width:80px;object-fit: contain'/>"
            )
          )
            .attr("src", event.target.result)
            .appendTo(imgPreview);
        };
        reader.readAsDataURL(input.files[i]);
      }
      $(".image_previews_remove").removeClass("d-none");
    }
  };

  if ($("#post_images").length) {
    $("#post_images").on("change", function () {
      images(this, "#post_image_previews");
    });
  }

  if ($("#share_images").length) {
    $("#share_images").on("change", function () {
      images(this, "#share_image_previews");
    });
  }

  if ($("#discussion_images").length) {
    $("#discussion_images").on("change", function () {
      images(this, "#discussion_image_previews");
    });
  }
  // if ($("#knowledge_article_images").length) {
  //   $("#knowledge_article_images").on("change", function () {
  //     images(this, "#knowledge_article_images_preview");
  //   });
  // }
  //clear the file list when image is clicked
  $(".image_previews_remove").on("click", function () {
    $("#post_images").val("");
    $("#post_image_previews").html("");
    $(".image_previews_remove").addClass("d-none");
  });
  $(".image_previews_remove").on("click", function () {
    $("#share_images").val("");
    $("#share_image_previews").html("");
    $(".image_previews_remove").addClass("d-none");
  });
  $(".image_previews_remove").on("click", function () {
    $("#discussion_images").val("");
    $("#discussion_image_previews").html("");
    $(".image_previews_remove").addClass("d-none");
  });
  // $(".image_previews_remove").on("click", function () {
  //   $("#knowledge_article_images").val("");
  //   $("#knowledge_article_images_preview").html("");
  //   $(".image_previews_remove").addClass("d-none");
  // });

  var message_files = function (input, filesPreview) {
    if (input.files) {
      var filesAmount = input.files.length;
      for (var i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        console.log(input.files[i].type);
        if (
          input.files[i].type == "image/x-png" ||
          input.files[i].type == "image/gif" ||
          input.files[i].type == "image/jpeg"
        ) {
          reader.onload = function (event) {
            $(
              $.parseHTML(
                "<img class='pic float-start me-2 mb-2' style='width:80px;height: 60px;object-fit: fill'/>"
              )
            )
              .attr("src", event.target.result)
              .appendTo(filesPreview);
          };
        } else if (input.files[i].type == "application/pdf") {
          var pdf_html =
            "<div class='float-start me-2 pt-2 mb-2 text-center' style='width:80px;height: 60px;'><i class='far fa-file-pdf font-26'></i> <div class='file-name font-10'>" +
            input.files[i].name +
            "</div></div>";
          reader.onload = function (event) {
            $($.parseHTML(pdf_html))
              .attr("src", event.target.result)
              .appendTo(filesPreview);
          };
        } else if (
          input.files[i].type == "doc" ||
          input.files[i].type == "docx" ||
          input.files[i].type == "application/msword" ||
          input.files[i].type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          var doc_html =
            "<div class='float-start me-2 pt-2 mb-2 text-center' style='width:80px;height: 60px;'><i class='far fa-file-word font-26'></i> <div class='file-name font-10'>" +
            input.files[i].name +
            "</div></div>";
          reader.onload = function (event) {
            $($.parseHTML(doc_html)).appendTo(filesPreview);
          };
        }

        reader.readAsDataURL(input.files[i]);
      }
      $(".message_files_preview_remove").removeClass("d-none");
    }
  };

  if ($("#message_files").length) {
    $("#message_files").on("change", function () {
      $(".message_files_preview").removeClass("d-none");
      message_files(this, "#message_files_preview .files");
    });
  }
  $(".message_files_preview_remove").on("click", function () {
    $("#message_files").val("");
    $("#message_files_preview").addClass("d-none");
    $("#message_files_preview .files").html("");
    $(".message_files_preview_remove").addClass("d-none");
  });

  var account_preview_post_linkUrlTypingTimer; //timer identifier
  var account_preview_post_linkUrlDoneTypingInterval = 1000; //time in ms (5 seconds)

  $("#account_preview_post_link_url").on("paste", function () {
    clearTimeout(account_preview_post_linkUrlTypingTimer);
    account_preview_post_linkUrlTypingTimer = setTimeout(
      account_preview_post_linkUrlDoneTyping,
      account_preview_post_linkUrlDoneTypingInterval
    );
  });
  function account_preview_post_linkUrlDoneTyping() {
    $(".js-share-data").html("");
    $("#account_preview_post_link_url_site").val(null);
    $("#account_preview_post_link_url_title").val(null);
    $("#account_preview_post_link_url_description").val(null);
    $("#account_preview_post_link_url_image").val(null);
    var url = $("#account_preview_post_link_url").val();
    $.ajax({
      url: "/fetch-url/grab?url=" + url,
      success: function (result) {
        console.log(result);
        var result_image = result["image"];
        var result_title = result["title"].substring(0, 50);
        var result_description = result["description"].substring(0, 120);
        $(".js-share-data").html(
          '<img src="' +
            result_image +
            '" class="w-100"><h5 class="mb-0 px-2 pt-2">' +
            result_title +
            '</h5><p class="mb-0 px-2 pb-2">' +
            result_description +
            "</p>"
        );
        $("#account_preview_post_link_url_site").val(result["site"]);
        $("#account_preview_post_link_url_title").val(result["title"]);
        $("#account_preview_post_link_url_description").val(
          result["description"]
        );
        $("#account_preview_post_link_url_image").val(result_image);
      },
    });
  }

  var post_linkUrlTypingTimer; //timer identifier
  var post_linkUrlDoneTypingInterval = 1000; //time in ms (5 seconds)

  $("#post_link_url").on("paste", function () {
    clearTimeout(post_linkUrlTypingTimer);
    post_linkUrlTypingTimer = setTimeout(
      post_linkUrlDoneTyping,
      post_linkUrlDoneTypingInterval
    );
  });
  function post_linkUrlDoneTyping() {
    $(".js-share-data").html("");
    $("#post_link_url_site").val(null);
    $("#post_link_url_title").val(null);
    $("#post_link_url_description").val(null);
    $("#post_link_url_image").val(null);
    var url = $("#post_link_url").val();
    $.ajax({
      url: "/fetch-url/grab?url=" + url,
      success: function (result) {
        console.log(result);
        var result_image = result["image"];
        var result_title = result["title"].substring(0, 50);
        var result_description = result["description"].substring(0, 120);
        $(".js-share-data").html(
          '<img src="' +
            result_image +
            '" class="w-100"><h5 class="mb-0 px-2 pt-2">' +
            result_title +
            '</h5><p class="mb-0 px-2 pb-2">' +
            result_description +
            "</p>"
        );
        $("#post_link_url_site").val(result["site"]);
        $("#post_link_url_title").val(result["title"]);
        $("#post_link_url_description").val(result["description"]);
        $("#post_link_url_image").val(result_image);
      },
    });
  }

  var startup_post_linkUrlTypingTimer; //timer identifier
  var startup_post_linkUrlDoneTypingInterval = 1000; //time in ms (5 seconds)

  $(".startup_post_link_url").on("paste", function () {
    clearTimeout(startup_post_linkUrlTypingTimer);
    startup_post_linkUrlTypingTimer = setTimeout(
      startup_post_linkUrlDoneTyping,
      startup_post_linkUrlDoneTypingInterval
    );
  });
  function startup_post_linkUrlDoneTyping() {
    $(".js-share-data").html("");
    $(".startup_post_link_url_site").val(null);
    $(".startup_post_link_url_title").val(null);
    $(".startup_post_link_url_description").val(null);
    $(".startup_post_link_url_image").val(null);
    var url = $(".startup_post_link_url").val();
    $.ajax({
      url: "/fetch-url/grab?url=" + url,
      success: function (result) {
        console.log(result);
        var result_image = result["image"];
        var result_title = result["title"].substring(0, 50);
        var result_description = result["description"].substring(0, 120);
        $(".js-share-data").html(
          '<img src="' +
            result_image +
            '" class="w-100"><h5 class="mb-0 px-2 pt-2">' +
            result_title +
            '</h5><p class="mb-0 px-2 pb-2">' +
            result_description +
            "</p>"
        );
        $(".startup_post_link_url_site").val(result["site"]);
        $(".startup_post_link_url_title").val(result["title"]);
        $(".startup_post_link_url_description").val(result["description"]);
        $(".startup_post_link_url_image").val(result_image);
      },
    });
  }

  // $('#post_link_url').on('keyup', function(e) {
  //   var url = $('#post_link_url').val();
  //   console.log(url)
  //   $.ajax({
  //     url: "/fetch-url/grab?url="+url,
  //     success: function(result) {
  //       var result_image = result["image"]
  //       var result_title = result["title"].substring(0, 50)
  //       var result_description = result["description"].substring(0, 120)
  //       $('.js-share-data').html('<img src="'+result_image+'" class="w-100"><h5 class="mb-0 px-2 pt-2">'+result_title+'</h5><p class="mb-0 px-2 pb-2">'+result_description+'</p>');
  //     }
  //   });
  // });

  var accountsLeftSidebar = $(".accounts-left-sidebar");
  $("#accountsSidebarCollapse").on("click", function (e) {
    accountsLeftSidebar.addClass("active");
  });
  $(".accounts-left-sidebar").on("click", function (e) {});
  $(".close-accounts-sidebar i").on("click", function () {
    accountsLeftSidebar.removeClass("active");
  });
  $(".has-accounts-sidebar").on("click", function () {
    accountsLeftSidebar.removeClass("active");
  });

  // ---------------------------------
  // ---------------------------------
  // Admin Panel
  // Toggle sidebar
  var leftSidebar = $(".admin-panel .left-sidebar");
  $("#sidebarCollapse").on("click", function () {
    leftSidebar.toggleClass("active");
  });

  // Initialize Tooltip
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
  );
});
